<template>
<vx-card no-shadow title="Profile" :customTitle="this.title()">
    <TermsAndCondition v-if="isOpenTerms" :isOpenTerms="isOpenTerms" @handleOpenTerms="handleOpenTerms" />
    <vs-divider class="mt-1 mb-0" />
    <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen" alignment="center">
        <vs-tab icon-pack="feather" icon="icon-user" :label="'Personal Details'">
            <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
                <Profile :selectedEmployee="employee" :salutation="this.salutations" @handleOpenTerms="handleOpenTerms" @updateEmployeeId="updateEmployeeId"></Profile>
            </div>
        </vs-tab>

        <vs-tab icon-pack="feather" icon="icon-map-pin" :label="'Home Address'">
            <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                <HomeAddress :selectedEmployee="this.employee"></HomeAddress>
            </div>
        </vs-tab>

        <vs-tab icon-pack="feather" icon="icon-mail" :label="'Mailing Address'">
            <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                <MailAddress :selectedEmployee="this.employee"></MailAddress>
            </div>
        </vs-tab>

        <vs-tab icon-pack="feather" icon="icon-credit-card" :label="'Driver\'s Licence'">
            <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
                <DrivingLicence :selectedEmployee="this.employee" :salutation="salutations"></DrivingLicence>
            </div>
        </vs-tab>

    </vs-tabs>
</vx-card>
</template>

<script>
import { profileData } from "../../store/api/profile"
import Profile from "./tabEmployerProfile.vue";
import HomeAddress from "./tabHomeAddress.vue";
import MailAddress from "./tabMailAddress.vue";
import DrivingLicence from "./tabDrivingLicence.vue";
import TermsAndCondition from "./TermsAndCondition.vue";

export default {
    data() {
        return {
            currentEmployeId: "",
            currentEmployeEmail: "",
            isOpenTerms: false,
            employee: {},
            salutations: ["Ms", "Miss", "Mr", "Mrs", "Dr", "Prof", "Rev"]
        };
    },
    components: {
        Profile,
        HomeAddress,
        MailAddress,
        DrivingLicence,
        TermsAndCondition
    },
    props: {
        selectedCompany: {
            type: Object,
        },
    },
    computed: {
        isSmallerScreen() {
            return this.$store.state.windowWidth < 768;
        },
    },
    async created() {
        this.getEmployee();
    },
    methods: {
        async getEmployee() {
          const getEmployeDetails = await profileData.SearchMyProfile()
          this.employee = getEmployeDetails;
          this.currentEmployeEmail = getEmployeDetails.email;
          this.currentEmployeId = getEmployeDetails.id;
        },
        title() {
            if (this.employee && this.employee.firstname) {
                return `${this.employee.firstname} ${this.employee.lastname}`;
            } else {
                return "";
            }
        },
        updateEmployeeId(id) {
            this.currentEmployeId = id;
        },
        handleOpenTerms() {
            this.isOpenTerms = !this.isOpenTerms;
        }
    }
};
</script>
