<template>
<div id="editForm">
    <vs-sidebar parent="editForm" hidden-background click-not-close position-right class="items-no-padding" v-model="active">
        <div class="h-full">
            <div class="customizer-header mt-6 flex items-center justify-between px-6">
                <div>
                    <h4>Terms And Conditions</h4>
                </div>
                <feather-icon icon="XIcon" @click.stop="$emit('handleOpenTerms')" class="cursor-pointer"></feather-icon>
            </div>
            <vs-divider class="mb-0" />
            <vx-pdf
                :key="fileURL"
                :src="fileURL"
            />
        </div>
    </vs-sidebar>
</div>
</template>

<script>
export default {
    props: {
        isOpenTerms: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            active: {},
            fileURL: "https://bhpstorage.blob.core.windows.net/cdn/Terms.pdf",

        };
    },
    watch: {
        isOpenTerms: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.active = newValue;
            }
        },
    },
    methods: {}
}
</script>

<style lang="scss">
#editForm {
    .vs-sidebar {
        position: fixed;
        z-index: 52000;
        min-width: 40vw;
        margin: 10px;
        max-height: 97vh;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    }
}
</style>
