<template>
<ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(saveProfile)">
        <h5 class="mt-4 text-secondary">Personal Details</h5>
        <vs-divider />

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Firstname:</span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" name="Firstname" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max:100 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-input class="sm:w-full lg:w-1/2" name="Firstname" v-model="employee.firstname" autocomplete="nope" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Lastname: </span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" name="Lastname" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max:100 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-input class="w-full" name="Lastname" v-model="employee.lastname" autocomplete="nope" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <h5 class="mb-2 text-secondary">Contact</h5>
        <vs-divider />

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Phone: </span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider  name="Phone" :rules="{ regex: /^[0-9]+$/, required: true, max: 10 }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-input class="sm:w-full md:w-2/3 lg:w-1/3" name="Phone" v-model="employee.mobileNumber" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right">Email: </span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <ValidationProvider class="w-full" name="Email" :rules="{ required: true, email: true }" v-slot="{ errors }" :custom-messages="customMessages">
                    <vs-input class="w-full" name="Email" v-model="employee.emailAddress" disabled readonly="true" />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                </ValidationProvider>
            </div>
        </div>

        <div class="vx-row mb-2">
            <div class="vx-col sm:w-1/3 w-full">
                <span class="text-right"></span>
            </div>
            <div class="vx-col sm:w-1/3 w-full">
                <vs-button class="mr-3 mb-2" @click.prevent="saveProfile">Submit</vs-button>
            </div>
        </div>
    </form>
</ValidationObserver>
</template>

<style lang="scss" scoped>
.text-right {
    float: right;
    margin-top: 5px;

    @media screen and (max-width: 1200px) {
        float: left;
    }
}
</style>

<script>
import moment from "moment";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import {
    profileData
} from "../../store/api/profile"

import {
    customMessages
} from "./../../filters/validationmessage";

export default {
    props: {
        selectedEmployee: {
            type: Object,
            default: () => {}
        },
        salutation: {
            type: Array
        },
        currentEmployeId: {
            type: String
        }
    },

    components: {
        flatPickr
    },

    data() {
        return {
            customMessages,
            employee: {},
        };
    },

    watch: {
        selectedEmployee: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.employee = newValue;
            }
        }
    },
    async created() {
        await this.loadEmployee()
    },

    methods: {
        async loadEmployee() {
            this.employee = await profileData.SearchMyProfile()

        },
        handleTerms() {
            this.$emit("handleOpenTerms");
        },
        async saveProfile() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            let apiResult = await profileData.saveProfile(this.employee);

            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
        }
    }
};
</script>
